import { NextRouter } from 'next/router';
import { Locales } from '../../../../tbd/old-routing';
import { GetLocalizeT } from '../../../utils/locales';
import { ILocations, Location, LocationWithTypeAndLink } from './model';

export function getRegionsFromLocations(locale: Locales, locations: ILocations[]): Location[] {
  const localizedLocations = locations.map((l) => l[locale]);
  return localizedLocations.map((l) => ({
    id: l.id,
    name: l.name,
    image: l.image,
    order: l.order,
  }));
}

export function getProvincesFromLocations(
  locale: Locales,
  locations: ILocations[],
): Location[] | undefined {
  const localizedLocations = locations.map((l) => l[locale]);
  return localizedLocations
    .map((l) => l.children || [])
    .reduce((curr, acc) => acc.concat(curr), [])
    .map((l) => ({
      id: l.id,
      name: l.name,
    }));
}

export function getCitiesFromLocations(
  locale: Locales,
  locations: ILocations[],
): Location[] | undefined {
  const localizedLocations = locations.map((l) => l[locale]);
  return localizedLocations
    .map((l) => l.children || [])
    .reduce((curr, acc) => acc.concat(curr), [])
    .map((l) => l.children || [])
    .reduce((curr, acc) => acc.concat(curr), [])
    .map((l) => ({
      id: l.id,
      name: l.name,
    }));
}

export function organizeLocationsResults(
  locale: Locales,
  locations: ILocations[],
  router: NextRouter,
  t: GetLocalizeT<string>,
): LocationWithTypeAndLink[] | undefined {
  const regions = getRegionsFromLocations(locale, locations);
  const provinces = getProvincesFromLocations(locale, locations);
  const cities = getCitiesFromLocations(locale, locations);

  let regionsWithTypeAndLink = [] as LocationWithTypeAndLink[];
  let provincesWithTypeAndLink = [] as LocationWithTypeAndLink[];
  let citiesWithTypeAndLink = [] as LocationWithTypeAndLink[];
  if (regions) {
    regionsWithTypeAndLink = regions.map((el) => ({
      ...el,
      type: t('filters.region', { ns: 'common' }),
      link: `/${locale}/search?regionId=${el.id}&mt=${el.name}`,
    }));
  }
  if (provinces) {
    provincesWithTypeAndLink = provinces.map((el) => ({
      ...el,
      type: t('filters.province', { ns: 'common' }),
      link: `/${locale}/search?provinceId=${el.id}&mt=${el.name}`,
    }));
  }
  if (cities) {
    citiesWithTypeAndLink = cities.map((el) => ({
      ...el,
      type: t('filters.city', { ns: 'common' }),
      link: `/${locale}/search?cityId=${el.id}&mt=${el.name}`,
    }));
  }

  return [...regionsWithTypeAndLink, ...provincesWithTypeAndLink, ...citiesWithTypeAndLink];
}
