import { KaajaLogo } from '@/components/common/kaaja-logo';
import { useIntl } from 'Features/Intl/context';
import { Modal } from 'Features/SRP/Components/Modal';
import { useSearchLocations } from 'Features/Shared/Utils/use-search-locations';
import { useLoop } from 'loop/context';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useReducer } from 'react';
import { TbChevronRight, TbLanguage, TbMenu2, TbSearch, TbUser } from 'react-icons/tb';
import { SearchField } from 'src/components/atoms/search-field/SearchField';

export function Navbar() {
  const { session } = useLoop();
  const { asPath } = useRouter();
  const { __ } = useIntl();

  interface State {
    isMenuExpanded: boolean;
    isSearchExpanded: boolean;
  }

  interface Action {
    type: 'TOGGLE_MENU' | 'TOGGLE_SEARCH';
  }

  const [state, dispatch] = useReducer(
    (state: State, action: Action) => {
      switch (action.type) {
        case 'TOGGLE_MENU':
          return { ...state, isMenuExpanded: !state.isMenuExpanded, isSearchExpanded: false };
        case 'TOGGLE_SEARCH':
          return { ...state, isSearchExpanded: !state.isSearchExpanded, isMenuExpanded: false };
        default:
          return state;
      }
    },
    {
      isMenuExpanded: false,
      isSearchExpanded: false,
    },
  );

  // Legacy Search Bar Code
  const locations = useSearchLocations();

  return (
    <div className="max-w-7xl mx-auto relative">
      <div className="hidden w-full px-6 h-24 md:items-center md:justify-between md:flex md:gap-5">
        <Link href="/" passHref>
          <a aria-label="Kaaja Homepage" className="shrink-0">
            <KaajaLogo />
          </a>
        </Link>

        {/* Legacy Search Bar */}
        <div className="max-w-sm w-full">
          <SearchField
            options={locations}
            propText="name"
            propLink="link"
            propDesc="type"
            bemType="desktop-header"
          />
        </div>

        <div className="flex items-center gap-10">
          <ul className="flex items-center gap-7 text-sm">
            <li className="uppercase font-semibold">
              <Link href="/services">{__('navbar.items.services')}</Link>
            </li>
            <li className="uppercase font-semibold">
              <Link href="/partnership">{__('navbar.items.collaborate')}</Link>
            </li>
            <li className="uppercase font-semibold">
              <Link href="/sell">{__('navbar.items.sell')}</Link>
            </li>
          </ul>
          <div className="flex items-center gap-3">
            <Link passHref href="/account">
              <a className="h-8 w-8 rounded-lg inline-flex items-center justify-center hover:bg-slate-100">
                <TbUser size={24} />
              </a>
            </Link>
            <Modal
              trigger={
                <button className="h-8 w-8 rounded-lg inline-flex items-center justify-center hover:bg-slate-100">
                  <TbLanguage size={24} />
                </button>
              }
              content={
                <div className="h-full space-y-5">
                  <div>
                    <h3 className="text-xl font-semibold tracking-tighter text-slate-900">
                      Seleziona Lingua
                    </h3>
                    <div className="mt-5 grid gap-5">
                      <Link passHref href={asPath} locale="it">
                        <a className="block px-2 py-4 bg-slate-100 rounded-md">
                          <div className="uppercase text-center font-semibold">Italiano</div>
                        </a>
                      </Link>
                      <Link passHref href={asPath} locale="en">
                        <a className="block px-2 py-4 bg-slate-100 rounded-md">
                          <div className="uppercase text-center font-semibold">English</div>
                        </a>
                      </Link>
                    </div>
                  </div>
                </div>
              }
            />
          </div>
        </div>
      </div>

      <div className="flex w-full px-6 h-24 items-center justify-between md:hidden">
        <button
          className="hover:bg-slate-100 p-1 rounded-xl"
          onClick={() => dispatch({ type: 'TOGGLE_MENU' })}
        >
          <TbMenu2 size={28} />
        </button>
        <Link href="/" passHref>
          <a aria-label="Kaaja Homepage" className="shrink-0">
            <KaajaLogo />
          </a>
        </Link>

        <button
          className="hover:bg-slate-100 p-1 rounded-xl"
          onClick={() => dispatch({ type: 'TOGGLE_SEARCH' })}
        >
          <TbSearch size={28} />
        </button>
      </div>

      {state.isSearchExpanded && (
        <div className="border-b border-t border-t-slate-100 border-b-slate-100 shadow">
          <div className="px-5 py-5">
            <SearchField
              options={locations}
              propText="name"
              propLink="link"
              propDesc="type"
              bemType="desktop-header"
            />
          </div>
        </div>
      )}

      {state.isMenuExpanded && (
        <div className="border-b border-t border-t-slate-100 border-b-slate-100 shadow">
          <ul className="divide-y divide-slate-100">
            {session?.user ? (
              <>
                <li>
                  <Link passHref href="/account">
                    <a className="px-6 py-4 flex items-center justify-between">
                      <span className="font-medium">{__('navbar.items.account')}</span>
                      <TbChevronRight size={20} className="text-slate-300" />
                    </a>
                  </Link>
                </li>
              </>
            ) : (
              <li>
                <Link passHref href="/v2/signin">
                  <a className="px-6 py-4 flex items-center justify-between">
                    <span className="font-medium">{__('navbar.items.login')}</span>
                    <TbChevronRight size={20} className="text-slate-300" />
                  </a>
                </Link>
              </li>
            )}

            <li>
              <Link passHref href="/">
                <a className="px-6 py-4 flex items-center justify-between">
                  <span className="font-medium">{__('navbar.items.homepage')}</span>
                  <TbChevronRight size={20} className="text-slate-300" />
                </a>
              </Link>
            </li>
            <li>
              <Link passHref href="/buy">
                <a className="px-6 py-4 flex items-center justify-between">
                  <span className="font-medium">{__('navbar.items.buy')}</span>
                  <TbChevronRight size={20} className="text-slate-300" />
                </a>
              </Link>
            </li>
            <li>
              <Link passHref href="/sell">
                <a className="px-6 py-4 flex items-center justify-between">
                  <span className="font-medium">{__('navbar.items.sell')}</span>
                  <TbChevronRight size={20} className="text-slate-300" />
                </a>
              </Link>
            </li>
            <li>
              <details className="marker:content-['']">
                <summary className="px-6 py-4 font-medium">{__('navbar.items.language')}</summary>
                <div className="px-6 pb-4 grid grid-cols-2 gap-5">
                  <Link passHref href={asPath} locale="it">
                    <a className="block px-2 py-2 bg-slate-100 rounded-md">
                      <div className="uppercase text-center font-semibold">Italiano</div>
                    </a>
                  </Link>
                  <Link passHref href={asPath} locale="en">
                    <a className="block px-2 py-2 bg-slate-100 rounded-md">
                      <div className="uppercase text-center font-semibold">English</div>
                    </a>
                  </Link>
                </div>
              </details>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
}
