import * as React from 'react';
import { cn } from './Input';

export interface CheckboxProps
  extends React.InputHTMLAttributes<HTMLInputElement> {}

export const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>(
  ({ className, type, ...props }, ref) => {
    return (
      <input
        type="checkbox"
        className={cn(
          'shrink-0 border-gray-200 rounded text-blue-600 focus:ring-blue-500',
          className
        )}
        ref={ref}
        {...props}
      />
    );
  }
);

Checkbox.displayName = 'Checkbox';
