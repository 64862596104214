import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createLanguageHeader, fetchV1Locations } from 'v2/http';
import { organizeLocationsResults } from '../../../src/core/http/locations';
import { LocationWithTypeAndLink } from '../../../src/core/http/locations/model';
import { useLocale } from '../../../src/core/locales/useLocale';

const useSearchLocations = () => {
  const router = useRouter();
  const { t } = useTranslation();
  const { locale } = useLocale();
  const [locations, setLocations] = useState<LocationWithTypeAndLink[]>([]);

  useEffect(() => {
    fetchV1Locations({
      headers: createLanguageHeader(locale),
    }).then((allLocations) => {
      const resultLocations = organizeLocationsResults(locale, allLocations, router, t);
      if (resultLocations) {
        setLocations(resultLocations);
      }
    });
  }, [locale]);

  return locations;
};

export { useSearchLocations };
