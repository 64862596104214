import * as RadixDialog from '@radix-ui/react-dialog';
import { AnimatePresence, motion } from 'framer-motion';
import * as React from 'react';
import { TbX } from 'react-icons/tb';

export interface DialogProps {
  trigger: React.ReactNode;
  content: React.ReactNode;
}

export function Modal({ trigger, content }: DialogProps) {
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <RadixDialog.Root open={isOpen} onOpenChange={setIsOpen}>
      <RadixDialog.Trigger asChild>{trigger}</RadixDialog.Trigger>
      <AnimatePresence>
        {isOpen && (
          <RadixDialog.Portal forceMount>
            <RadixDialog.Overlay>
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ type: 'spring', duration: 0.4 }}
                className="z-50 fixed inset-0 flex items-center justify-center bg-black/30 backdrop-filter backdrop-blur-sm"
              >
                <RadixDialog.Content asChild forceMount>
                  <motion.div
                    initial={{ y: 50, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    exit={{ y: 50, opacity: 0 }}
                    transition={{ type: 'spring', duration: 0.4 }}
                    className="relative bg-white shadow-sm rounded-xl w-[560px] h-[calc(100svh-40 px)] px-6 py-6 overflow-y-auto"
                  >
                    {content}

                    <RadixDialog.Close asChild>
                      <button
                        className="absolute text-xl rounded-lg h-6 w-6 inline-flex items-center justify-center top-4 right-4 hover:bg-zinc-200"
                        aria-label="Close"
                      >
                        <TbX />
                      </button>
                    </RadixDialog.Close>
                  </motion.div>
                </RadixDialog.Content>
              </motion.div>
            </RadixDialog.Overlay>
          </RadixDialog.Portal>
        )}
      </AnimatePresence>
    </RadixDialog.Root>
  );
}
