import classnames from 'classnames';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import {
  PropsWithChildren,
  ReactElement,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useLocale } from '../../../core/locales/useLocale';
import { useMediaQuery } from '../../../utils/effects/use-media-query.effect';
import { KeysMatching } from '../../../utils/types';

type SearchFieldProps<
  O extends { [key in T]: string } & Record<
    T | string,
    string | number | unknown
  >,
  T extends KeysMatching<O, string>,
  L extends KeysMatching<O, string>
> = {
  bemType?: 'default' | 'desktop-header' | 'mobile-header';
  readonly?: boolean;
  autoFocus?: boolean;
  options: O[];
  propText: T;
  propLink: L;
  propDesc?: string;
  selectedValue?: O[T];
  onBlur?: () => void;
};

export const SearchField = <
  O extends { [key in T]: string } & Record<
    T | string,
    string | number | unknown
  >,
  T extends KeysMatching<O, string>,
  L extends KeysMatching<O, string>
>({
  bemType = 'default',
  readonly = false,
  autoFocus = false,
  options,
  propText,
  propDesc,
  propLink,
  selectedValue,
  onBlur,
}: PropsWithChildren<SearchFieldProps<O, T, L>>): ReactElement | null => {
  const { t } = useTranslation();
  const { locale } = useLocale();
  const [showResults, setShowResults] = useState(false);
  const [searchField, setSearchField] = useState('');
  const searchInputRef = useRef<HTMLInputElement | null>(null);
  const searchDropdownRef = useRef<HTMLDivElement | null>(null);
  const [searchResults, setSearchResults] = useState<O[]>([]);
  const isMobile = useMediaQuery('(max-width: 992px)');
  const router = useRouter();
  const { basePath } = useRouter();

  const onSearchFocus = () => setShowResults(true);
  const onFocusLeave = () => {
    setShowResults(false);
    onBlur?.();
  };
  const onSearchClick = () => {
    if (searchField && searchResults.length > 0) {
      router.push(`${searchResults[0].link}`);
    }
  };

  useEffect(() => {
    const filteredOptions = options.filter(
      (o) => o[propText].toLowerCase().indexOf(searchField.toLowerCase()) !== -1
    );
    setSearchResults(filteredOptions);
  }, [searchField, options, locale]);

  useEffect(() => {
    selectedValue && setSearchField(selectedValue);
  }, [selectedValue]);

  useEffect(() => {
    const checkIfClickedOutside = (e: MouseEvent) => {
      if (
        showResults &&
        searchDropdownRef.current &&
        !searchDropdownRef.current.contains(e.target as Node) &&
        searchInputRef.current &&
        !searchInputRef.current.contains(e.target as Node)
      ) {
        onFocusLeave();
      }
    };

    document.addEventListener('mousedown', checkIfClickedOutside);

    return () =>
      document.removeEventListener('mousedown', checkIfClickedOutside);
  }, [showResults]);

  return (
    <div
      className={classnames({
        search_field: bemType === 'default',
        desktop_main_header__field: bemType === 'desktop-header',
        main_header__field: bemType === 'mobile-header',
      })}
    >
      <a className="search_field__anchor_fix" id="search_field"></a>

      <input
        className={classnames({
          search_field__input: bemType === 'default',
          desktop_main_header__field__input: bemType === 'desktop-header',
          main_header__field__input: bemType === 'mobile-header',
        })}
        type="text"
        onFocus={onSearchFocus}
        ref={searchInputRef}
        value={searchField}
        onChange={(e) => setSearchField(e.target.value)}
        readOnly={readonly}
        autoFocus={autoFocus}
        placeholder={t('header.searchPlaceholder')}
        onClick={() => {
          if (!isMobile) return;
          router.push(`${basePath}#search_field`);
        }}
      />
      <button
        className={classnames({
          search_field__button: bemType === 'default',
          desktop_main_header__field__button: bemType === 'desktop-header',
          main_header__field__button: bemType === 'mobile-header',
        })}
        onClick={onSearchClick}
      >
        <span>{t('homepage.search')}</span>
      </button>
      {showResults && searchField.length === 0 && (
        <div className="search_field__dropdown" ref={searchDropdownRef}>
          <h3 className="search_field__dropdown__title">{t('filters.area')}</h3>
          <ul className="search_field__dropdown__list">
            {propDesc &&
              searchResults
                .filter((o) => o[propDesc] === t('filters.region'))
                .map((o, index) => (
                  <li
                    key={index}
                    className="search_field__dropdown__list__item"
                  >
                    <a
                      onClick={() => {
                        setSearchField(o[propText]);
                      }}
                      href={o[propLink].replace("'", '-')}
                    >
                      {o[propText]}{' '}
                      {propDesc && o[propDesc] && (
                        <span className="search_field__dropdown__list__item__desc">
                          {' '}
                          - {o[propDesc]}
                        </span>
                      )}
                    </a>
                  </li>
                ))}
          </ul>
        </div>
      )}
      {searchField.length > 1 && showResults && (
        <div className="search_field__dropdown" ref={searchDropdownRef}>
          {searchResults.length > 0 && (
            <h3 className="search_field__dropdown__title">
              {t('filters.area')}
            </h3>
          )}
          <ul className="search_field__dropdown__list">
            {searchResults.length > 0 ? (
              searchResults.map((o, index) => (
                <li key={index} className="search_field__dropdown__list__item">
                  <a
                    onClick={() => {
                      setSearchField(o[propText]);
                    }}
                    href={o[propLink].replace("'", '-')}
                  >
                    {o[propText]}{' '}
                    {propDesc && o[propDesc] && (
                      <span className="search_field__dropdown__list__item__desc">
                        {' '}
                        - {o[propDesc]}
                      </span>
                    )}
                  </a>
                </li>
              ))
            ) : (
              <p>{t('header.searchNoResults')}</p>
            )}
          </ul>
        </div>
      )}
    </div>
  );
};
